<template>
  <div>
    <div class="thirdStepContainer">
      <div class="col">
        <h3 class="col__title">
          KvK/KBO-uittreksel
        </h3>
        <span class="col__text">
            We hebben een gewaarmerkt KvK/KBO-uittreksel van je nodig.
            <a
              href="https://www.kvk.nl/orderstraat/bedrijf-kiezen/?prefproduct=Digitaal+gewaarmerkt+uittreksel#!shop?&q=&prefproduct=Digitaal+gewaarmerkt+uittreksel"
              target="_blank"
            >
              Klik hier om het document te ondertekenen
            </a>
           van de Kamer van Koophandel en upload deze daarna hieronder.
        </span>
        <div class="col__upload">
          <a-upload-dragger
            name="kvk"
            :multiple="false"
            :customRequest="e => getFile(e, 'kvk')"
            @change="e => handleChangeFile(e, 'kvk')"
            :disabled="state.loadingFile"
            :fileList="fileList.kvk"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox"/>
            </p>
            <p class="ant-upload-text">
              Klik of sleep het bestand naar dit gebied om het te uploaden
            </p>
          </a-upload-dragger>
          <a-badge :status="state.kvk.errorText.length ? 'error' : 'success'"
                   v-show="state.kvk.isDirty"
                   :text="state.kvk.errorText.length ? 'error' : 'Goedgekeurd'"
          />
          <a-spin v-if="state.isFileLoading.kvk" style="margin-left: 5px">
            <a-icon slot="indicator" type="loading" style="font-size: 24px" spin/>
          </a-spin>
          <a-list size="small" bordered v-show="state.kvk.errorText.length" style="margin-top: 15px; overflow: hidden; text-overflow: ellipsis">
            <a-list-item v-for="(error, idx) in state.kvk.errorText" :key="idx + error">
              <a-badge :status="'error'"
                       :text="error"
              />
            </a-list-item>
          </a-list>
        </div>
      </div>
      <div class="col">
        <h3 class="col__title">
          <a-tooltip>
            <template slot="title">
              Je dient een paraaf neer te zetten op elke bladzijde bij ‘Opdrachtgever’! Ook dien je op pagina 1 en 4
              bedrijfs- en persoonsgegevens in te vullen bij de kopjes ‘Opdrachtgever / direct vertegenwoordigde’. Zorg
              ervoor dat alle info juist is ingevuld, anders kan het document niet juist verwerkt worden en kunnen wij
              geen producten voor jou inklaren.
            </template>
            Directe Vertegenwoordiging document
          </a-tooltip>
        </h3>
        <span class="col__text">
          Om goederen te kunnen inklaren, hebben we een ingevuld Directe Vertegenwoordiging document van je nodig. 
          Verifieer eerst je e-mail, klik daarna op de knop 'Ontvang document'.
        </span>
        <div class="col__upload">
          <a-upload-dragger
            name="dv"
            :multiple="false"
            :customRequest="e => getFile(e, 'dv')"
            @change="e => handleChangeFile(e, 'dv')"
            :disabled="state.loadingFile"
            :fileList="fileList.dv"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox"/>
            </p>
            <p class="ant-upload-text">
              Klik of sleep het bestand naar dit gebied om het te uploaden
            </p>
          </a-upload-dragger>
          <a-badge
            :status="state.dv.errorText.length ? 'error' : 'success'"
             v-show="state.dv.isDirty"
             :text="state.dv.errorText.length ? 'error' : 'Goedgekeurd'"
          />
          <a-spin v-if="state.isFileLoading.dv" style="margin-left: 5px">
            <a-icon slot="indicator" type="loading" style="font-size: 24px" spin/>
          </a-spin>
          <a-list size="small" bordered v-show="state.dv.errorText.length" style="margin-top: 15px">
            <a-list-item v-for="(error, idx) in state.dv.errorText" :key="error + idx">
              <a-badge :status="'error'"
                       :text="error"
              />
            </a-list-item>
          </a-list>
          <div style="display: flex; margin-top: 15px; justify-content: flex-end">
            <a-tooltip placement="top" >
              <template slot="title" v-if="!userInfo.emailVerifiedAt">
                <span>Gelieve uw e-mail te verifiëren om deze functie te gebruiken.</span>
              </template>
              <a-popconfirm placement="top" ok-text="Request automation sign" :disabled="!userInfo.emailVerifiedAt" cancel-text="Cancel" @confirm="automationSign">
               <template slot="title">
                  <p style="max-width: 300px">
                    Het automatische ondertekeningsproces wordt gebruikt om het maken
                    van documenten te vereenvoudigen. Ons systeem gebruikt de
                    HelloSign-service om u een bericht te sturen naar het e-mailadres
                    dat in uw account is opgegeven. Volg uw e-mailservice en voltooi de
                    stappen in het bericht. Keer na voltooiing terug naar deze pagina en
                    voltooi het registratieproces van het bedrijf.
                  </p>
                </template>
                <a-button
                  type="primary"
                  :disabled="this.fileList.dv.length > 0 || state.dv.helloSignIsComplite || !userInfo.emailVerifiedAt"
                >
                  Ontvang document
                </a-button>
              </a-popconfirm>
            </a-tooltip>
          </div>
          <div style="display: flex; margin-top: 15px">
            <a-badge
              :status="'success'"
              v-show="state.dv.helloSignIsComplite"
              :text="'Goedgekeurd'"
              style="margin-left: auto;"
            />
          </div>
        </div>
      </div>
    </div>
    <a-alert
      :message="state.errorStageMessage"
      type="error"
      close-text="Close Now"
      v-if="state.errorStageMessage.length"
    />
    <div>
      <a-spin v-if="state.isFileLoading.dv || state.isFileLoading.kvk">
        <a-icon slot="indicator" type="loading" style="font-size: 24px" spin/>
      </a-spin>
    </div>
  </div>

</template>

<script>

import { mapActions, mapMutations, mapState } from "vuex";
import FetchAbstraction from "@/utils/FetchAbstraction";
import { message } from 'ant-design-vue';
import { Popconfirm } from 'ant-design-vue';

const HELLOSIGN_CLIENT_ID = process.env.VUE_APP_HELLOSIGN_CLIENT_ID;
const NODE_ENV = process.env.NODE_ENV;

export default {
  name: 'CreateCompanySecondStep',
  components: {
    APopconfirm: Popconfirm,
  },
  props: {
    validators: Object,
    state: Object
  },
  beforeDestroy() {
    Object.keys(this.state).forEach(obj => {
      if (this.state[obj] && this.state[obj].isDirty) {
        this.doObjectEmpty(obj)
      }
    })
  },
  data() {
    return {
      onSuccess: false,
      isAutomationModalOpen: false,
      fileList: {
        kvk: [],
        dv: []
      },
    }
  },

  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      errorOnCreating: state => state.createCompany.errorOnCreating,
    }),
    dvDownloadUrl() {
      return `${process.env.VUE_APP_API_URL}/api/v1/dv/download`
    }
  },
  watch: {
    shouldCheckValidate(val) {
      if (val === true) {
        this.checkValidate()
      }
    }
  },
  methods: {
    ...mapActions([
      'checkIsFileValid',
    ]),
    ...mapMutations([
      'doObjectEmpty',
      'setFromHelloSignInState'
    ]),
    getFile({onSuccess}) {
      setTimeout(() => {
        this.onSuccess = true;
        onSuccess("ok");
      }, 0)
    },
    handleChangeFile(info, title) {
      this.fileList[title] = info.fileList;
      if (info.fileList.length >= 2) {
        this.fileList[title] = info.fileList.filter((file, idx, arr) => {
          return idx === arr.length - 1
        })
      }
      if (this.onSuccess && info.fileList.length) {
        this.checkIsFileValid({info, title});
        this.state[title].isDirty = true;
        this.onSuccess = false
      }
      if (!info.fileList.length) {
        this.state[title].isDirty = false;
        this.doObjectEmpty(title)
      }
    },
    checkValidate() {
      let res = false;
      let conditionHasFile = this.state.dv.file.size
        && this.state.kvk.file.size
      let conditionHasError =
        !this.state.dv.errorText.length
        && !this.state.kvk.errorText.length

      if (conditionHasFile && conditionHasError) {
        res = true
      }
      if (!res) {
        this.error = 'all field must be filled and must be correct';
        setTimeout(() => {
          this.error = ''
        }, 5000);
        this.$emit('validationFailed');
      } else {
        this.state.isStepFormValid = true;
        this.$emit('validationSuccess')
      }
    },
    async automationSign() {
      const hide = message.loading('loading...', 0);
      try {
        const response = await FetchAbstraction(true, 'GET', `/api/v1/hellosign-signature/send?document_name=direct_representation`)
        hide()
        if (response.track_id) {
          this.setFromHelloSignInState({flag: true, track_id: response.track_id, helloSignIsComplite: true, name: 'dv'});
          message.success('Great!');
        } else {
          message.error(response.message || 'Something went wrong');
        }
      } catch (e) {
        hide()
        message.error('Something went wrong');
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.thirdStepContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.col {
  width: 47%;
}

.inputFileWrapper {
  max-height: 120px;
}

.col__upload {
  margin-top: 20px;
}

</style>
