<template>
    <div class="formWrapper" style="width: 100%">
      <form
        v-if="!createCompany.isFormCompleted"
        class="form">
        <div class="form__progress">
          <div class="form__progress--title">
            <h2>
              {{ createCompany.currentFormStage === 1 ? 'Bedrijfsgegevens' : 'Documenten' }}
            </h2>
            <p>
              <span>{{ createCompany.currentFormStage }}</span>/{{ createCompany.formStageCount }}
            </p>
          </div>
          <a-progress
            type="line"
            size="small"
            strokeColor="#FFA300"
            :percent="(createCompany.currentFormStage / createCompany.formStageCount) * 100"
            :show-info="false"
          />
          <p class="form__privacy">
            {{
              createCompany.currentFormStage === 1
              ? 'We hebben deze gegevens van je nodig om je goederen te kunnen transporteren en in te klaren bij de douane. Door je aan te melden ga je akkoord met de Algemene Voorwaarden en de Privacy Verklaring.'
              : 'Zorg ervoor dat je de juiste documenten uploadt. Als je een Belgische klant bent vragen we om je KBO-uittreksel te uploaden. Je kunt een voorbeeld bekijken en een nieuw document uploaden.'
            }}
          </p>
        </div>
        <div>
          <CreateCompanyFirstStep
            v-show="createCompany.currentFormStage === 1"
            :validators="$v.createCompany.firstStep"
            :isValidatorPending="$v.$pending"
            @focusHandler="(providerName, bool, step) => focusHandler(providerName, bool, step)"
            :state="createCompany.firstStep"
          />
          <CreateCompanySecondStep
            v-show="createCompany.currentFormStage === 2"
            @focusHandler="(providerName, bool, step) => focusHandler(providerName, bool, step)"
            :state="createCompany.secondStep"
          />
          <a-alert
            :message="createCompany.errorOnCreating"
            type="error"
            close-text="Close Now"
            v-if="createCompany.errorOnCreating.length"
          />
          <a-button-group size="large" class="form__steps">
            <a-button type="primary"
                      :disabled="createCompany.currentFormStage === 1"
                      @click="createCompanyBackButtonHandler"
            >
              <a-icon type="left"/>
              Vorige stap
            </a-button>
            <a-button type="primary"
                      :disabled="createCompany.loading"
                      :loading="createCompany.loading"
                      @click="createCompanyNextStageHandler"
            >
              {{ createCompany.currentFormStage === 2 ? 'Voltooi registratie' : 'Volgende stap' }}
              <a-icon type="right"/>
            </a-button>
          </a-button-group>
        </div>
      </form>
    </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';

import CreateCompanyFirstStep from "@/components/dashboard/CreateCompanyFirstStep";
import CreateCompanySecondStep from "@/components/dashboard/CreateCompanySecondStep";
import {minLength, maxLength, required} from "vuelidate/lib/validators";
import FetchAbstraction from "@/utils/FetchAbstraction";
import router from "../../router";


export default {
  components: {
    CreateCompanyFirstStep,
    CreateCompanySecondStep,
  },
  watch: {
    eoriNameValue (newValue, oldValue) {
      if (this.checkedEoriNumber && newValue !== oldValue) {
        this.setEoriNumberChecked({ value: false })
      }
    }
  },
  computed: {
    ...mapState({
      createCompany: state => state.createCompany,
    }),
    eoriNameValue () {
      return this.createCompany.firstStep.eoriNumber.name;
    },
    checkedEoriNumber () {
      return this.createCompany.firstStep.eoriNumber.checked;
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  created() {
    window.addEventListener('beforeunload', this.onBeforeUnload)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.onBeforeUnload)
  },
  methods: {
    ...mapActions([
      'createCompanyFirstStepValidation',
      'createCompanySecondStepValidation',
      'getCompanyInfo',
      'getUser',
      'validateUniqueCodes'
    ]),
    ...mapMutations([
      'createCompanyBackButtonHandler',
      'clearState',
      'setEoriNumberChecked'
    ]),
    onBeforeUnload(e) {
      e.preventDefault();
      const confirmationMessage = 'Weet je zeker dat je de pagina opnieuw wilt laden? De ingevoerde gegevens worden niet opgeslagen.';
      (e || window.event).returnValue = confirmationMessage
      return confirmationMessage;
    },
    focusHandler(el, toFocus, step) {
        if (!toFocus) {
          this.$v.createCompany[step][el].$touch()
        }
        if (toFocus || (this.createCompany[step][el].name || typeof this.createCompany[step][el].name === 'number')) {
          this.createCompany[step][el].isFocused = true
          return;
        }
        this.createCompany[step][el].isFocused = false
    },
    createCompanyNextStageHandler() {
      const firstStep = this.createCompany.firstStep;
      
      if (this.createCompany.currentFormStage === 1) {
        if(firstStep.eoriNumber.isFocused & !firstStep.eoriNumber.checked) {
          firstStep.inputLoader = true
              let userEORI = {
                  number: this.eoriNameValue,
              };
              FetchAbstraction(false, 'POST', '/api/v1/verification/eori', userEORI)
                .then(() => {
                  this.$v.createCompany.firstStep.$touch();
                  if (!this.$v.createCompany.firstStep.$invalid) {
                    this.createCompanyFirstStepValidation()
                  }
                })
                .catch((e) => console.log(e))
                .finally(() => firstStep.inputLoader = false)
          return
        }
        this.$v.createCompany.firstStep.$touch()
        if (!this.$v.createCompany.firstStep.$invalid && firstStep.eoriNumber.checked) {
          this.createCompanyFirstStepValidation()
          return
        }
      }
      if (this.createCompany.currentFormStage === 2) {
        this.createCompanySecondStepValidation()
          .then(obj => {
            if (obj && obj.ok) {
              this.clearState()
              router.push({ name: 'DashboardInformation' })
              this.getCompanyInfo().then(() => this.getUser(true))
            }
          })
          .catch(e => console.log(e))
      }
    },
  },
  validations: {
    createCompany: {
      firstStep: {
        companyName: {
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(100)
          }
        },
        cityName: {
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(100)
          }
        },
        country: {
          name: {
            required,
            minLength: minLength(4),
            maxLength: maxLength(100)
          }
        },
        addition: {
          name: {
            minLength: minLength(1),
            maxLength: maxLength(100)
          }
        },
        houseNumber: {
          name: {
            required,
            maxLength: maxLength(100),
            minLength: minLength(1)
          }
        },
        streetHouseName: {
          name: {
            required,
            maxLength: maxLength(100),
            minLength: minLength(4)
          }
        },
        postCode: {
          name: {
            required,
            minLength: minLength(4),
            maxLength: maxLength(100)
          }
        },
        btwNumber: {
          name: {
            required,
            minLength: minLength(4),
            maxLength: maxLength(100)
          }
        },
        eoriNumber: {
          name: {
            required,
            maxLength: maxLength(100),
          },
          _nameForValidation: {
            async isValid(value) {
              if (!value) return true
              this.createCompany.firstStep.inputLoader = true
              let userEORI = {
                number: value,
              };
              return await FetchAbstraction(false, 'POST', '/api/v1/verification/eori', userEORI)
                .then((res) => {
                  this.setEoriNumberChecked({ value: true });
                  return res.ok
                })
                .catch((e) => console.log(e))
                .finally(() => this.createCompany.firstStep.inputLoader = false)
            }
          }
        },
        kvkNumber: {
          name: {
            required,
            maxLength: maxLength(100),
            minLength: minLength(4),
          }
        },
      },
    }
  }
}
</script>

<style scoped lang="scss">
.registerWrapper {
  display: flex;
  background: rgba(blue, 0.02);
  justify-content: center;
  min-height: 100vh;
}


.formWrapper {
  width: 50%;
  max-width: 700px;
  margin: 40px 0 40px 100px;
}

.formWrapper__form {
  &--label.focused {
    background: rgb(250, 250, 255);
  }
}

.formWrapper__toLoginLink {
  text-align: end;
  margin-bottom: 40px;
}

.form__progress {
  &--title {
    display: flex;
    justify-content: space-between;

    h2 {
      color: #FFA300;
      margin-bottom: 0;
      display: flex;
      align-items: flex-end;
    }

    p {
      margin-bottom: 0;
      display: flex;
      align-items: flex-end;

      span {
        color: #FFA300;
      }
    }
  }
}

.form__verifyCodeNumber {
  display: flex;
  justify-content: space-between;
}

.form__inlineInputs {
  display: flex;
  justify-content: space-between;

  & > div {
    width: 45%;
  }

}

.form__steps {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 40px 0;
}


</style>
