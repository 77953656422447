<template>
  <div>
    <div class="form__inlineInputs">
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="(validators.companyName.name.$dirty && validators.companyName.name.$invalid) ? 'error' : 'success'"
        >
          <template #help>
            <span
              v-show="validators.companyName.name.$dirty && validators.companyName.name.$invalid"
            >
              {{ state.companyName.dictionary }} onjuist
            </span>
          </template>
          <a-input
            allow-clear
            id="companyName"
            v-model="state.companyName.name"
            size="large"
            class="formWrapper__form--input"
            @focus="$emit('focusHandler', 'companyName', true, 'firstStep')"
            @blur="$emit('focusHandler', 'companyName', false, 'firstStep')"
          />
        </a-form-item>

        <label for="companyName"
               class="formWrapper__form--label"
               :class="state.companyName.isFocused ? 'focused' : ''"
        >
          {{ state.companyName.dictionary }}
        </label>
      </div>
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="(validators.cityName.name.$dirty && validators.cityName.name.$invalid) ? 'error' : 'success'"
        >
          <template #help>
            <span
              v-show="validators.cityName.name.$dirty && validators.cityName.name.$invalid"
            >
             Plaatsnaam onjuist
            </span>
          </template>
          <a-input
            allow-clear
            id="cityName"
            v-model="state.cityName.name"
            size="large"
            class="formWrapper__form--input"
            @focus="$emit('focusHandler', 'cityName', true, 'firstStep')"
            @blur="$emit('focusHandler', 'cityName', false, 'firstStep')"
          />
        </a-form-item>

        <label for="cityName"
               class="formWrapper__form--label"
               :class="state.cityName.isFocused ? 'focused' : ''"
        >
          Plaatsnaam
        </label>
      </div>
    </div>
    <div class="formWrapper__form--inputWrapper">
      <a-form-item
        :validate-status="(validators.country.name.$dirty && validators.country.name.$invalid) ? 'error' : 'success'"
      >
        <template #help>
            <span
              v-show="validators.country.name.$dirty && validators.country.name.$invalid"
            >
              {{ state.country.dictionary }} onjuist
            </span>
        </template>
        <a-input
          allow-clear
          id="country"
          v-model="state.country.name"
          size="large"
          class="formWrapper__form--input"
          @focus="$emit('focusHandler', 'country', true, 'firstStep')"
          @blur="$emit('focusHandler', 'country', false, 'firstStep')"
        />
      </a-form-item>

      <label for="country"
             class="formWrapper__form--label"
             :class="state.country.isFocused ? 'focused' : ''"
      >
        {{ state.country.dictionary }}
      </label>
    </div>
    <div class="formWrapper__form--inputWrapper">
      <a-form-item
        :validate-status="(validators.streetHouseName.name.$dirty && validators.streetHouseName.name.$invalid) ? 'error' : 'success'"
      >
        <template #help>
          <span
            v-show="validators.streetHouseName.name.$dirty && validators.streetHouseName.name.$invalid"
          >
            {{ state.streetHouseName.dictionary }} onjuist
          </span>
        </template>
        <a-textarea
          allow-clear
          :auto-size="{ minRows: 3, maxRows: 5 }"
          id="streetHouseName"
          v-model="state.streetHouseName.name"
          size="large"
          class="formWrapper__form--input"
          @focus="$emit('focusHandler', 'streetHouseName', true, 'firstStep')"
          @blur="$emit('focusHandler', 'streetHouseName', false, 'firstStep')"
        />
      </a-form-item>

      <label for="streetHouseName"
             class="formWrapper__form--label"
             :class="state.streetHouseName.isFocused ? 'focused' : ''"
      >
        {{ state.streetHouseName.dictionary }}
      </label>
    </div>
    <div class="formWrapper__form--inputWrapper">
      <a-form-item
        :validate-status="(validators.houseNumber.name.$dirty && validators.houseNumber.name.$invalid) ? 'error' : 'success'"
      >
        <template #help>
            <span
              v-show="validators.houseNumber.name.$dirty && validators.houseNumber.name.$invalid"
            >
               {{ state.houseNumber.dictionary }} onjuist
            </span>
        </template>
        <a-input
          allow-clear
          id="houseNumber"
          v-model="state.houseNumber.name"
          size="large"
          class="formWrapper__form--input"
          @focus="$emit('focusHandler', 'houseNumber', true, 'firstStep')"
          @blur="$emit('focusHandler', 'houseNumber', false, 'firstStep')"
        />
      </a-form-item>

      <label for="houseNumber"
             class="formWrapper__form--label"
             :class="state.houseNumber.isFocused ? 'focused' : ''"
      >
        {{ state.houseNumber.dictionary }}
      </label>
    </div>
    <div class="formWrapper__form--inputWrapper">
      <a-form-item
        :validate-status="(validators.addition.name.$dirty && validators.addition.name.$invalid) ? 'error' : 'success'"
      >
        <template #help>
            <span
              v-show="validators.addition.name.$dirty && validators.addition.name.$invalid"
            >
               {{ state.addition.dictionary }} onjuist
            </span>
        </template>
        <a-input
          allow-clear
          id="addition"
          v-model="state.addition.name"
          size="large"
          class="formWrapper__form--input"
          @focus="$emit('focusHandler', 'addition', true, 'firstStep')"
          @blur="$emit('focusHandler', 'addition', false, 'firstStep')"
        />
      </a-form-item>

      <label for="addition"
             class="formWrapper__form--label"
             :class="state.addition.isFocused ? 'focused' : ''"
      >
        {{ state.addition.dictionary }}
      </label>
    </div>
    <div class="formWrapper__form--inputWrapper">
      <a-form-item
        :validate-status="(validators.postCode.name.$dirty && validators.postCode.name.$invalid) ? 'error' : 'success'"
      >
        <template #help>
            <span
              v-show="validators.postCode.name.$dirty && validators.postCode.name.$invalid"
            >
             {{ state.postCode.dictionary }} onjuist
            </span>
        </template>
        <a-input
          allow-clear
          id="postCode"
          v-model="state.postCode.name"
          size="large"
          class="formWrapper__form--input"
          @focus="$emit('focusHandler', 'postCode', true, 'firstStep')"
          @blur="$emit('focusHandler', 'postCode', false, 'firstStep')"
        />
      </a-form-item>

      <label for="postCode"
             class="formWrapper__form--label"
             :class="state.postCode.isFocused ? 'focused' : ''"
      >
        {{ state.postCode.dictionary }}
      </label>
    </div>
    <div class="form__inlineInputs">
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="(validators.btwNumber.name.$dirty && validators.btwNumber.name.$invalid) ? 'error' : 'success'"
        >
          <template #help>
            <span
              v-show="validators.btwNumber.name.$dirty && validators.btwNumber.name.$invalid"
            >
              {{ state.btwNumber.dictionary }} onjuist
            </span>
          </template>
          <a-input
            allow-clear
            id="btwNumber"
            v-model="state.btwNumber.name"
            size="large"
            class="formWrapper__form--input"
            @focus="$emit('focusHandler', 'btwNumber', true, 'firstStep')"
            @blur="$emit('focusHandler', 'btwNumber', false, 'firstStep')"
          />
        </a-form-item>

        <label for="btwNumber"
               class="formWrapper__form--label"
               :class="state.btwNumber.isFocused ? 'focused' : ''"
        >
          {{ state.btwNumber.dictionary }}
        </label>
      </div>
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="(validators.eoriNumber.name.$dirty && validators.eoriNumber.name.$invalid && !state.inputLoader && !isValidatorPending) ? 'error' : 'success'"
        >
          <template #help>
            <h4 class="eori"
                v-show="validators.eoriNumber.name.$dirty && validators.eoriNumber.name.$invalid && !state.inputLoader && !isValidatorPending"
            >
              Deze EORI is niet geldig
            </h4>
            <span>Om erachter te komen wat je EORI-nummer is, bekijk dit <a
              href="https://ec.europa.eu/taxation_customs/dds2/eos/eori_validation.jsp"
              target="_blank">artikel</a></span>
          </template>
          <a-input-search
            allow-clear
            :loading="state.inputLoader"
            id="eoriNumber"
            size="large"
            v-model="state.eoriNumber.name"
            @change.native="eoriChangeHandler"
            class="formWrapper__form--input"
            @focus="$emit('focusHandler', 'eoriNumber', true, 'firstStep')"
            @blur="$emit('focusHandler', 'eoriNumber', false, 'firstStep')"
          />
        </a-form-item>
        <label for="eoriNumber"
               class="formWrapper__form--label"
               :class="state.eoriNumber.isFocused ? 'focused' : ''"
        >
          {{ state.eoriNumber.dictionary }}
        </label>
      </div>
    </div>
    <div class="form__inlineInputs">
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="(validators.kvkNumber.name.$dirty && validators.kvkNumber.name.$invalid) ? 'error' : 'success'"
        >
          <template #help>
            <span
              v-show="validators.kvkNumber.name.$dirty && validators.kvkNumber.name.$invalid"
            >
              KBO/{{ state.kvkNumber.dictionary }} onjuist
            </span>
          </template>
          <a-input
            allow-clear
            id="kvkNumber"
            v-model="state.kvkNumber.name"
            size="large"
            class="formWrapper__form--input"
            @focus="$emit('focusHandler', 'kvkNumber', true, 'firstStep')"
            @blur="$emit('focusHandler', 'kvkNumber', false, 'firstStep')"
          />
        </a-form-item>

        <label for="kvkNumber"
               class="formWrapper__form--label"
               :class="state.kvkNumber.isFocused ? 'focused' : ''"
        >
          KBO/{{ state.kvkNumber.dictionary }}
        </label>
      </div>
    </div>
    <a-list size="small" bordered
            v-show="state.isUniqueErrorsList.length"
            style="margin-top: 15px">
      <a-list-item v-for="(error, idx) in state.isUniqueErrorsList" :key="error + idx">
        <a-badge :status="'error'"
                 :text="error[0]"
        />
      </a-list-item>
    </a-list>
  </div>
</template>

<script>

export default {
  name: 'CreateCompanyFirstStep',
  props: {
    validators: Object,
    state: Object,
    isValidatorPending: Boolean
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {    
    eoriChangeHandler(e) {
      this.$store.commit('changeEoriValidateName', e.target.value);
    },
  },
}
</script>
